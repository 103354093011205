import React from 'react'
import styled from 'styled-components';

import { Button, Checkbox, Col, Input, Select, Spin, Table } from 'antd';

const GoldOutlineButton = styled(Button)`
  && {
    border: 1px solid #BC902D;
    border-radius: 0;
    color: #BC902D;

    &:hover, &:focus {
      border: 1px solid rgb(188, 144, 45, 0.8);
      color: rgb(188, 144, 45, 0.8);
    }
  }
`;

const GoldSolidButton = styled(Button)`
&& {
  background-color: #BC902D;
  border: none;
  border-radius: 0;
  color: #ffffff;

  &:hover, &:focus {
    background: rgb(188, 144, 45, 0.8);
    color: #ffffff;
  }
}
`;

const LeftColumnStyled = styled(Col)`
  height: calc(100vh - 65px);
  background-color: #FFFFFF;
  background-image: ${ p => `url(${process.env.PUBLIC_URL}/img/engagement_opt/${p.filename})` || 'none'};
  background-position: ${ p => p.position || 'center' };
  background-size: cover;
  padding: 0 5% !important;
`;

function LeftColumn(props) {
  return (
    <LeftColumnStyled md={24} lg={12} {...props} >
      { props.children }
    </LeftColumnStyled>
  );
}

const RightColumnStyled = styled(Col)`
  height: calc(100vh - 65px);
  background-color: #FFFFFF;
  padding: 0 5% !important;
  text-align: left;
  overflow-y: scroll;

  @media only screen and (max-width: 991px) {
    overflow-y: visible;
    width: 100%;
  }
`;

function RightColumn(props) {
  return (
    <RightColumnStyled md={24} lg={12} {...props} >
      { props.children }
    </RightColumnStyled>
  );
}

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked:after,
  .ant-pagination-item-active,
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: #BC902D;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #BC902D;
    border-color: #BC902D;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 0 !important;
  border-color: rgb(188, 144, 45, 0.2) !important;

  &:hover, &:focus {
    border-color: #BC902D !important;
  }

  &:focus{
    box-shadow: 0 0 0 2px rgb(188, 144, 45, 0.2) !important;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selection {
    border-radius: 0;
    border-color: rgb(188, 144, 45, 0.2) !important;
    width: 120px;
  }

  .ant-select-selection:hover, .ant-select-selection:focus {
    border-color: #BC902D;
  }

  .ant-select-selection:focus {
    box-shadow: 0 0 0 2px rgb(188, 144, 45, 0.2);
  }
`;

const StyledSpin = styled(Spin)`
  && {
    .ant-spin-dot i {
      background-color: #BC902D;
    }

    .ant-spin-text {
      color: #000000;
    }
  }
`;

const StyledTable = styled(Table)`
  th.actions,
  td.actions {
    text-align: center !important;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: #fff9ed;
  }

  a,
  .ant-pagination-item-active a,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a,
  .ant-table-thead > tr > th .ant-table-column-sorter-up.on,
  .ant-table-thead > tr > th .ant-table-column-sorter-down.on,
  .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter,
  .ant-table-filter-dropdown-link,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #BC902D;
  }

  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected,
  .ant-dropdown-menu-item-selected > a,
  .ant-dropdown-menu-submenu-title-selected > a {
    color: #BC902D;
    background-color: #fff9ed;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #fff9ed;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked:after,
  .ant-pagination-item-active,
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: #BC902D;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #BC902D;
    border-color: #BC902D;
  }
`;

const Title = styled.div`
  padding: 50px 0;
  color: #BC902D;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 76px;

  @media only screen and (max-width: 991px) {
    padding: 30px 0;
    font-size: 50px;
    line-height: 56px;
  }
`;

export {
  GoldOutlineButton,
  GoldSolidButton,
  LeftColumn,
  RightColumn,
  StyledCheckbox,
  StyledInput,
  StyledSelect,
  StyledSpin,
  StyledTable,
  Title,
}
