import ApiClient from './ApiClient';

const AuthService = {
  isLoggedIn() {
    return sessionStorage.token !== undefined;
  },

  login(data, successCallback, errorCallback) {
    ApiClient.post('/login', data)
      .then(resp => {
        const { token } = resp.data;

        AuthService.setSessionAuth(token);

        successCallback(resp);
      })
      .catch(error => {
        delete ApiClient.defaults.headers.common.Authorization;

        errorCallback(error);
      });
  },

  logout() {
    delete ApiClient.defaults.headers.common.Authorization;

    sessionStorage.removeItem('token');
  },

  setSessionAuth(token) {
    sessionStorage.token = token;
  },
};

export default AuthService;
