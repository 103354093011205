import React from 'react';
import styled from 'styled-components';

import { Col, Row } from 'antd';

import Page from './Page';

const VendorBox = styled.div`
  border: 1px solid #000000;
  width: 100%;
  height: 200px;
  margin-bottom: 25px;

  a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 65%;
    max-height: 65%;
    object-fit: contain;
  }
`;

const RegistryVendor = ({ url, filename, alt }) => {
  return (
    <Col xs={24} sm={12} md={24} lg={12}>
      <VendorBox>
        <a href={url} target='_blank' rel='noopener noreferrer'>
          <img src={`${process.env.PUBLIC_URL}/img/registry/${filename}`}
            alt={alt}/>
        </a>
      </VendorBox>
    </Col>
  )
}

function Registry() {
  return(
    <Page title='We need some stuffs' filename='81.jpg' position='50% 75%'>
      <Row gutter={24}>
        <RegistryVendor url='https://www.heathceramics.com/apps/giftregistry/registry/69592'
              filename='heath-logo.jpg' alt='heath ceramics logo' />
        <RegistryVendor url='https://www.amazon.com/wedding/alli-fitzpatrick-matthew-gray-los-gatos-august-2019/registry/3BLHE1V40IKC8'
              filename='amazon-logo.png' alt='amazon wedding registry logo' />
        <RegistryVendor url='https://www.williams-sonoma.com/registry/27cmcdjb9g/registry-list.html'
              filename='williams-sonoma-logo.png' alt='williams sonoma logo' />
        <RegistryVendor url='https://www.cb2.com/gift-registry/allison-fitzpatrick-and-matthew-gray/r5934420'
              filename='cb2-logo.jpg' alt='cb2 logo' />
        <RegistryVendor url='https://www.crateandbarrel.com/gift-registry/allison-fitzpatrick-and-matthew-gray/r5975170'
              filename='crate-and-barrel-logo.jpg' alt='create and barrel logo' />
      </Row>
    </Page>
  );
}

export default Registry;
