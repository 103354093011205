import React, { useEffect } from 'react';
import styled from 'styled-components';

import { LeftColumn, RightColumn, Title } from './StyledComponents';

const Wrapper = styled.div`
  width: 100%;
`;

const LeftColumnVariableHeight = styled(LeftColumn)`
  @media only screen and (max-width: 767px) {
    height: 200px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    height: 300px;
  }
`;

const ChildrenWrapper = styled.div`
  padding-bottom: 60px;
`;

function Page({ children, filename, position, title }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <Wrapper>
      <LeftColumnVariableHeight filename={filename} position={position} />
      <RightColumn>
        <Title>{title}</Title>
        <ChildrenWrapper>
          { children }
        </ChildrenWrapper>
      </RightColumn>
    </Wrapper>
  );
}

export default Page;
