import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { Layout, Menu } from 'antd';

const StyledHeader = styled(Layout.Header)`
  height: 65px;
  background-color: #fafafa;
  border-bottom: 1px solid #cfcfcf;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  @media only screen and (max-width: 800px) {
    height: 78px;
    padding: 0 10px;
    text-align: center;
  }
`;

const Names = styled.div`
  float: left;
  color: #000000;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media only screen and (max-width: 800px) {
    float: none;
    font-size: 18px;
    line-height: 48px;
  }
`;

const StyledMenu = styled(Menu)`
  && {
    float: right;
    line-height: 60px;
    border-bottom: none;
    background-color: #fafafa;

    @media only screen and (max-width: 800px) {
      float: none;
      width: 100%;
      line-height: 16px;
      border-bottom: none;
      background-color: #fafafa;
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  && {
    padding: 0;
    margin: 0 20px;
    line-height: 24px;
    vertical-align: middle !important;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid transparent !important;

    &:hover {
      border-bottom: 1px solid #BC902D !important;
    }

    a {
      color: #000000;

      &:hover {
        color: #BC902D !important;
      }
    }

    &.ant-menu-item-selected a {
      color: #BC902D !important;
    }

    &.ant-menu-item-selected {
      border-bottom: 1px solid #BC902D !important;
    }

    @media only screen and (max-width: 800px) {
      line-height: 18px;
      font-size: 12px;
    }
  }
`;

function Header(props) {
  return(
    <StyledHeader>
      <Link to='/'>
        <Names>Alli & Matthew</Names>
      </Link>
      <StyledMenu mode='horizontal' selectedKeys={[props.location.pathname]}>
        <StyledMenuItem key='/our-story'><Link to='/our-story'>Our Story</Link></StyledMenuItem>
        <StyledMenuItem key='/rsvp'><Link to='/rsvp'>RSVP</Link></StyledMenuItem>
        <StyledMenuItem key='/registry'><Link to='/registry'>Registry</Link></StyledMenuItem>
      </StyledMenu>
    </StyledHeader>
  );
}

export default withRouter(Header);
