import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Alert, Button, Col, Input, Row } from 'antd';

import { StyledSpin } from './StyledComponents';

import ApiService from '../utils/ApiService';

import Page from './Page';

const FormWrapper = styled.div`
  border: 1px solid #BC902D;
  padding: 30px;
`;

const StyledP = styled.p`
  color: #aaaaaa;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 16px;
`;

const StyledAlert = styled(Alert)`
  && {
    margin-bottom: 25px;
  }
`;

const GoButton = styled(Button)`
  && {
    width: 100%;
    background: #000000;
    color: #ffffff;
    border: none;
    border-radius: 0;
    font-weight: 600;

    &:hover, &:focus {
      background: #BC902D;
      color: #ffffff;
    }
`;

const StyledInput = styled(Input)`
  && {
    border-radius: 0;
    text-transform: uppercase;
    color: #BC902D;
    font-size: 18px;
    letter-spacing: 0.03em;
    font-weight: 200;
    margin-bottom: 10px;

    &:hover, &:focus {
      border-color: #BC902D;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(188, 144, 45, 0.2);
    }
  }
`;

function Error(props) {
  const errorMessage = "Oh no! We didn't find your invitation 😭";
  const errorDescription = "Don't worry, we're sure you're on the invite list..." +
    "double check your invite code and try again.";

  return(
    <StyledAlert type='error' message={errorMessage} description={errorDescription} />
  );
}

function Directive(props) {
  return (
    <StyledP>
      Enter your invitation code to retrieve your invite
    </StyledP>
  );
}

function Rsvp(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inviteId, setInviteId] = useState(null);
  const [inviteCode, setInviteCode] = useState('');
  const [redirectToInvitation, setRedirectToInvitation] = useState(false);

  const handleChange = (e) => {
    if (error) { setError(false) };
    setInviteCode(e.target.value);
  };

  const handleClick = (e) => {
    setLoading(true);
    ApiService.checkInviteCode(inviteCode)
      .then((invite) => {
        console.log(invite);
        setInviteId(invite.id);
        setLoading(false);
        setRedirectToInvitation(true);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  if (redirectToInvitation) {
    return (
      <Redirect push to={{ pathname: `/invitations/${inviteId}` }} />
    )
  }

  const spinnerTip = 'Sit tight, your invitation is loading.'

  return(
    <Page title="Come rock out with us" filename='107.jpg' position='40% 30%'>
      <StyledSpin spinning={loading} tip={spinnerTip} className={props.className}>
        <Row align='middle'>
          <FormWrapper>
            <Row>
              { error ? (<Error />) : null }
              <Directive />
            </Row>
            <Row align='middle' gutter={24}>
              <Col xs={24} sm={18}>
                <StyledInput size='large' placeholder='AM082419' onChange={handleChange}/>
              </Col>
              <Col xs={24} sm={6}>
                { inviteCode === '' ?
                  (<GoButton disabled size='large' onClick={handleClick}>Go</GoButton>) :
                  (<GoButton size='large' onClick={handleClick}>Go</GoButton>)
                }
              </Col>
            </Row>
          </FormWrapper>
        </Row>
      </StyledSpin>
    </Page>
  );
}

export default withRouter(Rsvp);
