import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Row, Col } from 'antd';

const StyledRow = styled(Row)`
  padding: 20% 5% 0;
  text-align: center;
`;

const ErrorCode = styled.h1`
  color: #BC902D;
  font-size: 100px;
  margin-bottom: 0;
  line-height: normal;
  font-weight: 100;

  @media only screen and (max-width: 800px) {
    font-size: 60px
  }
`;

const ErrorDescription = styled.div`
  letter-spacing: 0.02em;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
    height: 40px;
    color: #000000;
    border-color: #000000;
    border-radius: 0;
    font-weight: 300;
    text-transform: uppercase;

    &:hover, &:focus {
      color: #BC902D;
      border-color: #BC902D;
    }
`;


const NotFound = (props) => {
  return(
    <StyledRow type='flex' align='middle'>
      <Col span={24}>
        <ErrorCode>404</ErrorCode>
        <ErrorDescription>
          Uh oh, the page you are looking for could not be found.
        </ErrorDescription>
        <Link to='/'>
          <StyledButton>go to homepage</StyledButton>
        </Link>
      </Col>
    </StyledRow>
  );
}

export default NotFound;
