import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Col, Drawer, Dropdown, Form, Icon, Menu, message, Modal, Row, Tag } from 'antd';

import { GoldOutlineButton, GoldSolidButton, StyledCheckbox, StyledInput, StyledSelect,
  StyledSpin, StyledTable } from '../StyledComponents';

import ApiService from '../../utils/ApiService';

const Wrapper = styled.div`
  text-align: left;
  padding: 0 50px;

  @media only screen and (max-width: 800px) {
    padding: 10px;
  }
`;

const Title = styled.div`
  padding: 20px 0;
  color: #BC902D;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 76px;

  @media only screen and (max-width: 800px) {
    padding: 30px 0;
    font-size: 50px;
    line-height: 56px;
  }
`;

const StatWrapper = (props) => {
  return(
    <Row style={{ marginBottom: '10px' }}>
      { props.children }
    </Row>
  );
};

const StatName = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @media only screen and (max-width: 400px) {
    font-size: 14px;
    letter-spacing: 0.02em;
  }
`;

const StatValue = styled.div`
  font-size: 60px;
  font-weight: 100;
  line-height: 65px;
  color: #BC902D;

  @media only screen and (max-width: 400px) {
    font-size: 48px;
    line-height: 54px;
  }
`;

const Stat = ({ name, value }) => {
  return(
    <Col xs={12} lg={6} style={{ marginBottom: '10px' }}>
      <StatName>{ name }</StatName>
      <StatValue>{ value }</StatValue>
    </Col>
  );
};

const NewGuestsButton = ({ onClick }) => {
  let style = {
    top: '-20px',
  };

  if (window.innerWidth < 600) {
    style.display = 'none';
  }

  return (
    <GoldOutlineButton style={style} onClick={() => onClick(true)}><Icon type='plus' />Add Guests</GoldOutlineButton>
  );
}

const NewGuestsDrawer = Form.create({ name: 'newGuests' })(({ form, visible, handleClose, addGuests }) => {
  const [newGuests, setNewGuests] = useState([]);
  const { getFieldDecorator, getFieldValue, getFieldsValue, resetFields } = form;

  const removeGuest = (guest) => {
    const guests = newGuests.filter(e => e.firstName !== guest.firstName && e.lastName !== guest.lastName);
    setNewGuests(guests);
  }

  const columns = [{
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  }, {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
  }, {
    title: 'First name',
    dataIndex: 'lastName',
    key: 'lastName',
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  }, {
    title: '',
    dataIndex: '',
    key: 'remove',
    render: (guest) => (<Button type='danger' size='small' shape='circle' icon='close' onClick={() => removeGuest(guest)}/>)
  }];

  const handleSubmit = (e) => {
    e.preventDefault();

    const emails = newGuests.filter(e => e.email !== undefined && e.email !== null);
    if (newGuests.length < 1) {
      message.error('At least one guest needs to be added.')
      return;
    } else if (emails.length < 1) {
      message.error('At least one guest needs to have an email.');
      return;
    }

    const sendInvitations = getFieldValue('sendInvitations');

    ApiService.createGuests(newGuests, sendInvitations)
      .then(res => {
        setNewGuests([]);
        addGuests(res.data);
        handleClose();
        const guestNames = res.data.map(e => `${e.firstName} ${e.lastName} `);
        message.success(`Added ${guestNames} to the list.`);
      })
      .catch(err => message.error('Failed to add new guests. Please try again.'));
  }

  const addGuest = () => {
    const newGuest = getFieldsValue();
    if (newGuest.title && newGuest.firstName && newGuest.lastName) {
      setNewGuests(newGuests.concat(newGuest));
      resetFields();
    } else {
      message.error('Guests need at least a title, along with a first and last name.')
    }
  }

  const close = () => {
    setNewGuests([]);
    form.resetFields();
    handleClose();
  }

  return (
    <Drawer title='Add New Guests'
      placement='right'
      closable={true}
      onClose={close}
      width={500}
      visible={visible}
    >
      <Form layout='vertical' onSubmit={handleSubmit}>
        <Form.Item label='Title'>
          {getFieldDecorator('title')(
            <StyledSelect>
              <StyledSelect.Option value='Mrs.'>Mrs.</StyledSelect.Option>
              <StyledSelect.Option value='Ms.'>Ms.</StyledSelect.Option>
              <StyledSelect.Option value='Mx.'>Mx.</StyledSelect.Option>
              <StyledSelect.Option value='Mr.'>Mr.</StyledSelect.Option>
            </StyledSelect>
          )}
        </Form.Item>
        <Form.Item label='First name'>
          {getFieldDecorator('firstName')(
            <StyledInput type='text' placeholder='Alli' />
          )}
        </Form.Item>
        <Form.Item label='Last name'>
          {getFieldDecorator('lastName')(
            <StyledInput type='text' placeholder='Fitzpatrick' />
          )}
        </Form.Item>
        <Form.Item label='Email'>
          {getFieldDecorator('email')(
            <StyledInput type='text' placeholder='allifitzpatrick1@gmail.com' />
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <GoldOutlineButton onClick={addGuest}><Icon type='plus' /> Add another guest</GoldOutlineButton>
        </Form.Item>
        <Form.Item>
          <StyledTable columns={columns} dataSource={newGuests} size='middle' pagination={false} />
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('sendInvitations', { valuePropName: 'checked', initialValue: false })(
            <StyledCheckbox>Send invitations to listed email addresses?</StyledCheckbox>
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <GoldSolidButton htmlType="submit">Save Guests</GoldSolidButton>
          <GoldOutlineButton style={{ marginLeft: '15px' }} onClick={close}>
            Cancel
          </GoldOutlineButton>
        </Form.Item>
      </Form>
    </Drawer>
  )
});

const EditGuestDrawer = Form.create({ name: 'editGuest' })(({ form, guest, handleClose, updateGuests }) => {
  const { getFieldDecorator, getFieldValue } = form;

  useEffect(() => {
    form.setFieldsValue(guest);
  }, [guest]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const guestParams = {
      id: getFieldValue('id'),
      firstName: getFieldValue('firstName'),
      lastName: getFieldValue('lastName'),
      email: getFieldValue('email'),
      response: getFieldValue('response'),
      title: getFieldValue('title'),
      inviteId: getFieldValue('inviteId'),
      locked: getFieldValue('isLocked')
    };

    ApiService.updateGuest(guestParams)
      .then(res => {
        updateGuests(res.data);
        handleClose();
        message.success(`${guestParams.firstName} ${guestParams.lastName}
          updated.`)
      })
      .catch(err => message.error(`Failed to update ${guestParams.firstName}
        ${guestParams.lastName}`));
  }

  return (
    <Drawer title='Edit Guest'
      placement='right'
      closable={true}
      onClose={handleClose}
      visible={guest !== null}
      width={300}
    >
      <Form layout='vertical' onSubmit={handleSubmit}>
        <Form.Item label='ID'>
          {getFieldDecorator('id')(
            <StyledInput type='text' disabled />
          )}
        </Form.Item>
        <Form.Item label='Title'>
          {getFieldDecorator('title')(
            <StyledSelect>
              <StyledSelect.Option value='Mrs.'>Mrs.</StyledSelect.Option>
              <StyledSelect.Option value='Ms.'>Ms.</StyledSelect.Option>
              <StyledSelect.Option value='Mx.'>Mx.</StyledSelect.Option>
              <StyledSelect.Option value='Mr.'>Mr.</StyledSelect.Option>
            </StyledSelect>
          )}
        </Form.Item>
        <Form.Item label='First name'>
          {getFieldDecorator('firstName')(
            <StyledInput type='text' placeholder='Alli' />
          )}
        </Form.Item>
        <Form.Item label='Last name'>
          {getFieldDecorator('lastName')(
            <StyledInput type='text' placeholder='Fitzpatrick' />
          )}
        </Form.Item>
        <Form.Item label='Email'>
          {getFieldDecorator('email')(
            <StyledInput type='text' placeholder='allifitzpatrick1@gmail.com' />
          )}
        </Form.Item>
        <Form.Item label='Response'>
          {getFieldDecorator('response')(
            <StyledSelect>
              <StyledSelect.Option value='attending'>Attending</StyledSelect.Option>
              <StyledSelect.Option value='awaiting'>Awaiting</StyledSelect.Option>
              <StyledSelect.Option value='declined'>Declined</StyledSelect.Option>
            </StyledSelect>
          )}
        </Form.Item>
        <Form.Item label='Invite ID'>
          {getFieldDecorator('inviteId')(
            <StyledInput type='text' placeholder='' disabled />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('isLocked', { valuePropName: 'checked' })(
            <StyledCheckbox>Locked</StyledCheckbox>
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <GoldSolidButton htmlType="submit">Update</GoldSolidButton>
          <GoldOutlineButton style={{ marginLeft: '15px' }} onClick={handleClose}>
            Cancel
          </GoldOutlineButton>
        </Form.Item>
      </Form>
    </Drawer>
  )
});

const invitationMenu = (guest) => {
  const confirmSaveTheDate = (guest) => {
    Modal.confirm({
      title: 'Are you sure you want to send a Save the Date?',
      content: 'This operation cannot be undone, once you click OK the email will be sent and you won\'t be able to retract it.',
      onOk() {
        ApiService.sendSaveTheDate(guest.id);
      },
      onCancel() {},
    });
  };

  const confirmInvitation = (guest) => {
    Modal.confirm({
      title: 'Are you sure you want to send an Invitation?',
      content: 'This operation cannot be undone, once you click OK the email will be sent and you won\'t be able to retract it.',
      onOk() {
        ApiService.sendInvitation(guest.id);
      },
      onCancel() {},
    });
  };

  const confirmReminder = (guest) => {
    Modal.confirm({
      title: 'Are you sure you want to send a reminder?',
      content: 'This operation cannot be undone, once you click OK the email will be sent and you won\'t be able to retract it.',
      onOk() {
        ApiService.sendReminder(guest.id);
      },
      onCancel() {},
    });
  };

  const handleClick = (item) => {
    switch(item.key) {
      case '1':
        return confirmSaveTheDate(item.item.props.guest);
      case '2':
        return confirmInvitation(item.item.props.guest);
      case '3':
        return confirmReminder(item.item.props.guest);
      default:
        break;
    }
  }
  return (
    <Menu onClick={handleClick}>
      <Menu.Item guest={guest} key='1' disabled><Icon type='calendar' />Send Save the Date</Menu.Item>
      <Menu.Item guest={guest} key='2' disabled={guest.isLocked ? true : false}><Icon type='mail' />Send Invitation</Menu.Item>
      <Menu.Item guest={guest} key='3' disabled={guest.isLocked ? true : false}><Icon type='clock-circle' />Send Reminder</Menu.Item>
    </Menu>
  );
};

const GuestList = (props) => {
  const [currentGuest, setCurrentGuest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [guests, setGuests] = useState([]);
  const [invitedGuests, setInvitedGuests] = useState(0);
  const [attendingGuests, setAttendingGuests] = useState(0);
  const [awaitingGuests, setAwaitingGuests] = useState(0);
  const [declinedGuests, setDeclinedGuests] = useState(0);
  const [showNewGuestsDrawer, setShowNewGuestsDrawer] = useState(false);

  const updateGuests = (guest) => {
    const updatedGuests = guests.slice(0);

    for (var i = 0; i < updatedGuests.length; i++) {
      if (updatedGuests[i].id === guest.id) {
        guest.key = guest.id;
        updatedGuests[i] = guest;
      } else if (updatedGuests[i].inviteId === guest.inviteId) {
        updatedGuests[i].rsvpTime = guest.rsvpTime;
        updatedGuests[i].isLocked = guest.isLocked;
      }
    }

    setGuests(updatedGuests);
  }

  const addGuests = (newGuests) => {
    setGuests(newGuests.concat(guests));
  }

  const columns = [{
    title: 'Invite',
    dataIndex: 'inviteId',
    key: 'inviteId',
    render: (inviteId, guest) => (<Link to={`/invitations/${guest.inviteParam}`} target='new'> { inviteId }</Link>),
  }, {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a, b) => {
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
    },
  }, {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: (a, b) => {
      if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
      if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
    },
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  }, {
    title: 'Response',
    dataIndex: 'response',
    key: 'response',
    filters: [
      {
        text: 'Attending',
        value: 'attending',
      }, {
        text: 'Awaiting',
        value: 'awaiting',
      },
      {
        text: 'Declined',
        value: 'declined',
      },
    ],
    onFilter: (value, guest) => guest.response.indexOf(value) === 0,
    sorter: (a, b) => {
      if (a.response < b.response) return -1;
      if (a.response > b.response) return 1;
    },
    render: response => {
      let color;
      switch (response) {
        case 'attending':
          color = 'green';
          break;
        case 'declined':
          color = 'red';
          break;
        default:
          color = 'blue';
          break;
      }

      return (<Tag color={color}>{response}</Tag>);
    }
  }, {
    title: 'RSVP Time',
    dataIndex: 'rsvpTime',
    key: 'rsvpTime',
  }, {
    title: '',
    key: 'actions',
    className: 'actions',
    render: (guest) => {
      return (
        <GoldSolidButton.Group>
          <Dropdown overlay={invitationMenu(guest)} trigger={['click']}>
            <GoldSolidButton size='small' icon='notification' title='Invitation menu' />
          </Dropdown>
          <GoldSolidButton size='small' icon='edit' title='Edit guest'
            onClick={() => { openEditGuestDrawer(guest) }} />
        </GoldSolidButton.Group>
      )
    },
  }];

  useEffect(() => {
    ApiService.getAllGuests()
      .then((guests) => {
        guests.forEach((guest) => guest.key = guest.id)
        setGuests(guests);
        setLoading(false);
      })
      .catch((err) => {
        props.history.push('/login');
      });
  }, []);

  useEffect(() => {
    setInvitedGuests(guests.length);
    setAwaitingGuests(guests.reduce((a, e) => e.response === 'awaiting' ? a + 1 : a + 0, 0));
    setAttendingGuests(guests.reduce((a, e) => e.response === 'attending' ? a + 1 : a + 0, 0));
    setDeclinedGuests(guests.reduce((a, e) => e.response === 'declined' ? a + 1 : a + 0, 0));
  }, [guests]);

  const closeEditGuestDrawer = () => setCurrentGuest(null);
  const openEditGuestDrawer = (guest) => setCurrentGuest(guest);
  const closeNewGuestsDrawer = () => setShowNewGuestsDrawer(false);

  return(
    <Wrapper>
    <NewGuestsDrawer visible={showNewGuestsDrawer} handleClose={closeNewGuestsDrawer}
      addGuests={addGuests} />
      <EditGuestDrawer visible={currentGuest !== null} guest={currentGuest}
        handleClose={closeEditGuestDrawer} updateGuests={updateGuests} />

      <Title>The Guest List <NewGuestsButton style={{ top: '-20px' }} onClick={setShowNewGuestsDrawer} /></Title>
      <StyledSpin spinning={loading}>
        <StatWrapper>
          <Stat name='Invited' value={invitedGuests} />
          <Stat name='Attending' value={attendingGuests} />
          <Stat name='Awaiting' value={awaitingGuests} />
          <Stat name='Declined' value={declinedGuests} />
        </StatWrapper>
      </StyledSpin>
      <StyledTable columns={columns} dataSource={guests} size='middle' pagination={{ pageSize: 25, position: 'both' }}/>
    </Wrapper>
  )
}

export default withRouter(GuestList);
