import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { Layout } from 'antd';

import './App.css';

import AuthService from './utils/AuthService';

// Public
import Header from './components/Header';
import Home from './components/Home';
import Invitation from './components/Invitation';
import Hotels from './components/Hotels';
import Login from './components/Login';
import OurStory from './components/OurStory';
import Rsvp from './components/Rsvp';
import Registry from './components/Registry';

// Admin
import GuestList from './components/admin/GuestList';

// Error
import NotFound from './components/errors/NotFound';

const StyledLayout = styled(Layout)`
`;

const Wrapper = styled.div`
  margin-top: 65px;
  background-color: #ffffff;

  @media only screen and (max-width: 800px) {
    margin-top: 78px;
  }
`;

function App() {
  return(
    <Router>
      <StyledLayout>
        <Header />
        <Wrapper>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/admin/guest-list' render={ () => AuthService.isLoggedIn() ? <GuestList /> : <Redirect to={{ pathname: '/login', state: { from: '/admin/guest-list' } }} /> } />
            <Route exact path='/invitations/:id' component={Invitation} />
            <Route exact path='/hotels' component={Hotels} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/our-story' component={OurStory} />
            <Route exact path='/rsvp' component={Rsvp} />
            <Route exact path='/registry' component={Registry} />
            <Route component={NotFound} />
          </Switch>
        </Wrapper>
      </StyledLayout>
    </Router>
  );
}

export default App;
