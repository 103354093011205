import React, { useState } from 'react';

import { Alert, Col, Form, Row } from 'antd';

import { GoldOutlineButton, GoldSolidButton, StyledInput } from './StyledComponents';

import AuthService from '../utils/AuthService';

const Error = () => {
  const message = 'Uh oh, invalid password 🤔';
  const description = 'You entered an invalid password. Please double check ' +
   'the password you are using and try again.';
  return (
    <Alert style={{ maxWidth: '500px', margin: '0 auto 20px' }} type='error'
      message={message} description={description} />
  )
}

const Login = (props) => {
  const [error, setError] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    props.form.resetFields();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    AuthService.login({ token: props.form.getFieldValue('password') }, () => {
      props.history.push('/admin/guest-list');
    }, () => {
      setError(true);
      props.form.resetFields();
    });
  }
  const { getFieldDecorator } = props.form;

  return(
    <Row style={{ paddingTop: '20%', margin: '0 10px' }} type='flex' align='middle'
      justify='center'>
      <Col xs={24} sm={20} md={16} lg={8}>
        { error ? <Error /> : null }
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ margin: '0 auto 10px', width: '250px' }}>
            {getFieldDecorator('password')(
              <StyledInput type="password" placeholder="Enter your password" />
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <GoldSolidButton htmlType="submit">Log in</GoldSolidButton>
            <GoldOutlineButton style={{ marginLeft: '15px' }} onClick={handleReset}>
              Cancel
            </GoldOutlineButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Form.create({ name: 'login' })(Login);
