import ApiClient from './ApiClient';

export default class ApiService {

  // Guest API
  static getAllGuests() {
    return ApiClient.get('/guests/')
      .then((res) => res.data);
  }

  static sendInvitation(guestId) {
    return ApiClient.patch(`/guests/${guestId}/send_invitation`);
  }

  static sendReminder(guestId) {
    return ApiClient.patch(`/guests/${guestId}/send_reminder`);
  }

  static sendSaveTheDate(guestId) {
    return ApiClient.patch(`/guests/${guestId}/send_save_the_date`);
  }

  static createGuests(guests, sendInvitations) {
    return ApiClient.post('/guests', { guests: guests, sendInvitations: sendInvitations });
  }

  static updateGuest(guest) {
    return ApiClient.put(`/guests/${guest.id}`, {
      firstName: guest.firstName,
      lastName: guest.lastName,
      response: guest.response,
      title: guest.title,
      email: guest.email,
      locked: guest.locked
    });
  }

  static updateGuestResponse(id, response, inviteCode, inviteUuid) {
    return ApiClient.patch(`/guests/${id}/response`, { response: response });
  }

  // Invite API
  static checkInviteCode(inviteCode) {
    return ApiClient.get(`/invites/check/${inviteCode}`, { inviteCode: inviteCode })
      .then((res) => res.data);
  }

  static getInvitationById(id) {
    return ApiClient.get(`/invites/${id}`)
      .then((res) => res.data);
  }

  static updateInviteResponses(inviteResponses) {
    return ApiClient.put(`/invites/${inviteResponses.id}/update_responses`, inviteResponses);
  }

}
