import axios from 'axios';

const ApiClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(config => {
    const token = sessionStorage.token;

    const newConfig = config;
    if (token != null) {
      newConfig.headers.Authorization = token;
    } else {
      newConfig.headers.Authorization = null;
    }

    return newConfig;
  });

  return instance;
};

export default ApiClient();
