import React from 'react';
import styled from 'styled-components';

import { Timeline } from 'antd';

import Page from './Page';

const StyledTimelineItem = styled(Timeline.Item)`
  &&{
    .ant-timeline-item-tail {
      border: 1px solid #BC902D;
    }

    .ant-timeline-item-head {
      border-color: #BC902D;
    }
  }
`;

const Date = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

const Event = styled.p`
  font-size: 16px;
`;

function OurStory() {
  return(
    <Page title='Our Story' filename='22.jpg' position='20% 90%'>
      <Timeline>
        <StyledTimelineItem>
          <Date>May 2012 - SoMa, San Francisco</Date>
          <Event>
            Fresh out of UC Davis, Alli starts work at a scrappy, young startup
            called Touch of Modern.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>June 2015 - SoMa, San Francisco</Date>
          <Event>
            A budding Software Engineer, Matthew returns home from Chicago to
            find his first engineering gig. After a few interviews, he lands a
            job at Touch of Modern.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>January 2016 - Fort Mason, San Francisco</Date>
          <Event>
            At the Touch of Modern holiday party, Alli receives an award. Matthew
            notices. Later that night, Matthew dances the night away. Alli notices.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>May 26, 2016 - Potrero Hill, San Francisco</Date>
          <Event>
            Alli and Matthew both find themselves on the prowl for an afternoon
            snack, stumbling upon some leftovers from a management meeting. They
            each sense a familiar desire within the other, namely where to find
            their next meal.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>May 27, 2016 - Potrero Hill, San Francisco</Date>
          <Event>
            With the previous day's interaction fresh in his mind, Matthew DMs
            Alli on the work messaging platform (Slack). He crafts a witty remark
            about the lack of bacon in the previous day's leftovers.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>June 2016 - Potrero Hill, San Francisco</Date>
          <Event>
            The weeks are filled with flirtatious Slack conversations
            and <em>coincidentally</em> timed elevator rides. Matthew asks for Alli's
            number to continue the conversation out of sight from Human Resources.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>July 2016 - Mission District, San Francisco</Date>
          <Event>
            The first date. Tummies aflutter, the two meet at Elixir, cashing in
            on Matthew's Broke Ass Stuart Beer Passports (thanks Craig). After
            enjoying a Sculpin or two, they move on to a couple more bars and
            eventually end the night at Taqueria Cancún for a burrito night cap,
            Alli's treat. Matthew walks Alli to her door, awaits an awkward kiss,
            and floats back over the hill to Hayes Valley.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>September 2016 - Potrero Hill, San Francisco</Date>
          <Event>
            Matthew leaves Touch of Modern, sensing that he has fallen in love
            with his co-worker.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>March 31, 2018 - Union Square, San Francisco</Date>
          <Event>
            After searching for the perfect ring, Matthew and Alli find the one.
            Matthew cries at the sight of it and Alli looks forward to wearing it
            some day.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>Early April 2018 - San Bruno</Date>
          <Event>
            Matthew tells Alli that he is going to the wood shop to work on a project.
            In reality, he is heading to San Bruno to tell John and Kathy his
            plans to marry their daughter. He picks up the Fitzpatricks' favorite
            pizza, hard cider, and some hazy IPA to soften the blow.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>April 27, 2018 - Mission District, San Francisco</Date>
          <Event>
            Matthew returns home from a work trip to Chicago and tells Alli to keep
            the night open for a special evening. He gives her a primitive, hand
            drawn re-creation of the Beer Passport from their first date. The
            two set out on foot for the first stop, Elixir. Along the way,
            he drops hints about a big surprise at the end of the night, making
            Alli confident a proposal is imminent. Much to Alli's chagrin, the
            surprise turns out to be a super burrito from Taqueria Cancún.
            Matthew has effectively thrown her off.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>April 28, 2018, Morning - Mission District, San Francisco</Date>
          <Event>
            Upon waking up, Alli confesses to Matthew that she thought the previous
            night was going to be <em>the night</em>. Matthew feels bad about
            throwing her off, but knows what he has planned for the afternoon.
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>April 28, 2018, Afternoon - Marin Headlands</Date>
          <Event>
            Alli attends a wedding shower in San Mateo while Matthew hectically
            scrambles to prepare their apartment for an evening of celebration
            with family and friends. Matthew had told Alli about a special clearance
            sale happening at Heath in Sausalito in the afternoon, knowing
            that she wouldn't resist discounted ceramics. As they cross the Golden
            Gate, Matthew wants to take in the view from Hawk Hill and exits the
            freeway. Parking mid-day on a Saturday is impossible but this
            is <em>the spot</em>. After some stressful minutes of searching,
            a space opens up and they hit the trail for a quick look at the bridge.
            In a secluded area with golden wildflowers in full bloom, Matthew's
            hands and voice start to shake. Trying to hold back tears of joy, he
            drops to a knee and asks her to be his partner in life, forever.
            Alli says yes!
          </Event>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <Date>August 24, 2019 - Los Gatos</Date>
          <Event>
            Alli and Matthew exchange vows in front of their families and friends,
            eat lots of food, drink the haziest of beers, and dance the night
            away!
          </Event>
        </StyledTimelineItem>
      </Timeline>
    </Page>
  );
}

export default OurStory;
