import React from 'react';

import Page from './Page';

function Hotels(props) {
  return(
    <Page title="Enjoy Your Stay" filename='6.jpg'>
    </Page>
  );
}

export default Hotels;
