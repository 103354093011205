import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Carousel } from 'antd';

const Wrapper = styled.div`
  height: calc(100vh - 65px);
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    height: calc(var(--vh) * 100 - 78px);
  }
`;
const Slide = styled.img`
  height: calc(100vh - 65px);
  width: 100%;
  object-fit: cover;
  object-position: ${ p => p.position || '50% 50%' };

  @media only screen and (max-width: 800px) {
    height: calc(var(--vh) * 100 - 78px);
    padding: 0;
  }
`;

function Home() {
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('deviceorientation', handleResize);
  }, []);

  return(
    <Wrapper>
      <Carousel autoplay autoplaySpeed={3600} speed={900} dots={false}>
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/1.jpg`}
            alt='Alli and Matthew enjoying a picnic' position='25% 80%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/7.jpg`}
            alt='Tomales Bay Oyster Company' position='50% 65%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/15.jpg`}
            alt='Alli and Matthew setting up a picnic' position='40% 100%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/16.jpg`}
            alt='Matthew setting up a picnic' position='50% 100%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/17.jpg`}
            alt='Alli carrying oysters in a net bag' position='50% 25%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/22.jpg`}
            alt='Alli and Matthew setting up a picnic' position='20% 100%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/27.jpg`}
            alt='A picnic blanket with oysters, cheese, meat, and beer'
            position='35% 100%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/55.jpg`}
            alt='Alli and Matthew laughing and eating oysters'
            position='25% 15%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/47.jpg`}
            alt='Alli and Matthew enjoying a picnic on the coast'
            position='25% 100%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/71.jpg`}
            alt='Oysters on a platter full of ice with a shucker'
            position='40% 60%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/59.jpg`}
            alt='Alli and Matthew standing on coast' position='35% 80%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/57.jpg`}
            alt='Matthew kissing Alli on the forehead as they stand on the coast'
            position='25% 50%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/94.jpg`}
            alt='Matthew hugging Alli as she looks over the coast'
            position='55% 80%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/113.jpg`}
            alt='Alli and Matthew standing on coast laughing' position='70% 65%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/132.jpg`}
            alt='Alli laughing' position='60% 65%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/122.jpg`}
            alt='Alli and Matthew embracing and laughing' position='60% 60%' />
        <Slide src={`${process.env.PUBLIC_URL}/img/engagement_opt/148.jpg`}
            alt='Matthew kissing her hand' position='0% 60%' />
      </Carousel>
    </Wrapper>
  );
}

export default Home;
