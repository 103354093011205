import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { message, Radio, Row } from "antd";

import {
  GoldSolidButton,
  LeftColumn,
  RightColumn,
  StyledSpin,
  StyledTable,
  Title
} from "./StyledComponents";

import ApiService from "../utils/ApiService";

const Wrapper = styled.div`
  width: 100%;
`;

const HotelImg = styled.img`
  width: 100%;
  max-width: 408px;
`;

const StyledRadio = styled(Radio)`
  display: block !important;
  height: 30px;
  line-height: 30px;

  && {
    .ant-radio-checked .ant-radio-inner {
      border-color: #bc902d;
    }

    .ant-radio-inner:after {
      background-color: #bc902d;
    }
  }
`;

const RsvpButton = styled(GoldSolidButton)`
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  width: 100% !important;
  height: 50px !important;
`;

const SmallerTitle = styled(Title)`
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.02em;
`;

const Error = () => {
  return message.error('All guests on this invitation need to have a response.', 5);
};

const Success = ({ hasAttendingGuests }) => {
  let text;

  if (hasAttendingGuests) {
    text = "We look forward to seeing you on our special day <3";
  } else {
    text =
      "We will miss you on our special day, but know you will be with us in spirit <3";
  }

  return message.success(text, 5);
};

const InvitationImg = styled.img`
  width: auto !important;
  width: 100%;
  max-width: 500px;

  @media only screen and (max-width: 580px) {
    max-width: 95%;
  }

  @media only screen and (min-width: 581px) and (max-width: 699px) {
    max-width: 80%;
  }

  @media only screen and (min-width: 700px) and (max-width: 830px) {
    max-width: 75%;
  }
`;

function Invitation(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasAttendingGuests, setHasAttendingGuests] = useState(false);
  const [inviteDetails, setInviteDetails] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);

    const params = props.match.params;

    ApiService.getInvitationById(params.id).then(invite => {
      invite.guests.forEach(guest => {
        guest.key = guest.id;
        guest.name = `${guest.firstName} ${guest.lastName}`;
      });

      setInviteDetails(invite);
      setLoading(false);
    });
  }, []);

  const handleResponseChange = event => {
    setError(false);

    const guestId = event.target.guestid;
    const updatedGuests = inviteDetails.guests.slice(0);

    for (var i = 0; i < updatedGuests.length; i++) {
      if (updatedGuests[i].id === guestId) {
        const guest = updatedGuests[i];
        guest.response = event.target.value;
        updatedGuests[i] = guest;

        break;
      }
    }

    inviteDetails.guests = updatedGuests;
    setInviteDetails(inviteDetails);
  };

  const handleSubmit = () => {
    let error = false;

    const guests = inviteDetails.guests.map(guest => {
      if (guest.response === "awaiting" || guest.response === undefined) {
        error = true;
      }

      if (guest.response === "attending") {
        console.log("here");
        setHasAttendingGuests(true);
      }

      return { id: guest.id, response: guest.response };
    });

    setError(error);

    if (!error) {
      const payload = {
        id: inviteDetails.id,
        guests: guests
      };

      ApiService.updateInviteResponses(payload).then(() => {
        setSuccess(true);
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Response",
      dataIndex: "response",
      key: "response",
      render: (response, guest) => {
        return (
          <Radio.Group onChange={handleResponseChange} value={response}>
            <StyledRadio
              guestid={guest.id}
              disabled={inviteDetails.locked || success}
              value="attending"
            >
              Attending
            </StyledRadio>
            <StyledRadio
              guestid={guest.id}
              disabled={inviteDetails.locked || success}
              value="declined"
            >
              Not Attending
            </StyledRadio>
          </Radio.Group>
        );
      }
    }
  ];

  return (
    <StyledSpin spinning={loading}>
      <Wrapper>
        <Row
          type="flex"
          align="middle"
          style={{ height: "calc(100vh - 65px)" }}
        >
          <LeftColumn>
            <Row
              type="flex"
              align="middle"
              justify="center"
              style={{ height: "calc(100vh - 65px)" }}
            >
              <InvitationImg
                alt="Invitation to Alli and Matthew's wedding August 24th, 2019"
                src={`${process.env.PUBLIC_URL}/img/invitation.png`}
              />
            </Row>
          </LeftColumn>
          <RightColumn>
            <SmallerTitle>RSVP</SmallerTitle>
            {success ? (
              <Success hasAttendingGuests={hasAttendingGuests} />
            ) : null}
            {error ? <Error /> : null}
            <StyledTable
              dataSource={inviteDetails.guests}
              columns={columns}
              loading={loading}
              pagination={false}
            />
            <RsvpButton
              disabled={inviteDetails.locked || success}
              onClick={handleSubmit}
            >
              Submit Response
            </RsvpButton>
            <div>
              <SmallerTitle>Lodging</SmallerTitle>
              <div>
                <HotelImg
                  src={`${process.env.PUBLIC_URL}/img/hotels/garden.png`}
                  alt="Los Gatos Garden Inn Hotel"
                />
                <h2>Los Gatos Garden Inn Hotel</h2>
                <p>
                  Located off Main Street in downtown Los Gatos, mention our
                  wedding and you will receive the group rate.
                </p>
                <p>
                  46 E Main Street
                  <br />
                  Los Gatos, CA 95030
                  <br />
                  (408) 354-6446
                </p>
              </div>
              <div style={{ padding: "25px" }} />
              <div>
                <HotelImg
                  src={`${process.env.PUBLIC_URL}/img/hotels/lodge.jpg`}
                  alt="Los Gatos Lodge"
                  width={408}
                />
                <h2>Los Gatos Lodge</h2>
                <p>
                  Located off Highway 9 in Los Gatos, mention our wedding and
                  you will receive the group rate.
                </p>
                <p>
                  50 Los Gatos-Saratoga Road
                  <br />
                  Los Gatos, CA 95032
                  <br />
                  (408) 354 - 3300
                </p>
              </div>
              <div style={{ padding: "50px" }} />
            </div>
          </RightColumn>
        </Row>
      </Wrapper>
    </StyledSpin>
  );
}

export default withRouter(Invitation);
